import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Helmet from "react-helmet";
import Layout from 'components/Layout';
import BlogPost from 'components/pages/BlogPost';
import { Image } from 'components/pages/BlogPost/styled';
import { ImgGatsbyBackground } from 'components/wrappers';
import styled, { css } from 'styled-components';
import { blogPosts } from 'common/blogPostsFeatured';
import BlogBaseCard from 'components/blog/BaseCard';
import BlogSidebar from 'components/blog/BlogSidebar';
import {PostsWrapper} from 'components/pages/Blog/styled';
import { useIntl, Link, FormattedMessage } from "gatsby-plugin-intl";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from 'react-share';
import FacebookDefaultIcon from 'images/social-share-icons/facebook-white.png';
import TwitterDefaultIcon from 'images/social-share-icons/twitter-white.png';
import LinkedinDefaultIcon from 'images/social-share-icons/linkedin-white.png';
import BlogOptin from 'components/blog/BlogOptin';
import {Clear, BlogRow, ShareButton, ReadNow, DateText, TitleText, UnorderedList, OrderedList, FirstTitle, BottomShare, SectionFull, OptinSection, ColumnHalf, ColumnHalfLast} from 'components/blog/SingleBlogStyle';

import Trademark2Img from 'images/blog-images/infringement-issues-trademark2.png';
import InfringementIssuesFeatured from 'images/featured-image/infringement-issues-featured.png';
import TrademarkImg from 'images/blog-images/infringement-issues-trademark.png';


interface OwnProps {
  location: {
    href: string;
  };
}

const BlogArticle = ({ location }: OwnProps) => {
  const data = useStaticQuery(graphql`
    query InfringementIssuesImg {
      file(relativePath: { eq: "trademark.png" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1400) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  const BackgroundImage = () => <ImgGatsbyBackground fluid={data.file.childImageSharp.fluid} alt="infringement issues shipkoo"/>;
  const { href } = location;
  const intl = useIntl()
  return (
    <Layout>
      <Helmet 
        // title="Infringement Issues | Shipkoo"
        title={intl.formatMessage({ id: "blog-post-meta-title-infringement-issues" })}
         meta={
          [
            {
              name: `description`,
              content: "",
            },
            {
              property: `og:title`,
              // content: "Infringement Issues",
              content: intl.formatMessage({ id: "blog-post-meta-title-infringement-issues" }),
            },
            {
              property: `og:description`,
              content: "",
            },
            {
              property: `og:image`,
              content: InfringementIssuesFeatured,
            },
            {
              property: `og:type`,
              content: `website`,
            },
            {
              name: `twitter:card`,
              content: `summary`,
            },
          ] as JSX.IntrinsicElements['meta'][]
        }
      />
      <BlogPost
        location={location}
        id="infringement-issues"
        title="Infringement Issues"
        date="2020-09-03"
        author="Joe Zhang"
        Background={BackgroundImage}
      >
        <BlogRow>
          <BlogSidebar/>
          <div className="column-half last">

            <p>
              Many online sellers need to face the problem of intellectual property. One of the main reasons  for the need to register trademarks or other types of intellectual property products is mainly because of platform requirements. Moreover, this can also bring out some values to online sellers, such as trademark registration, A+ page, etc. For some of the big-scaled online sellers, intellectual property rights are both a means of operation and a value-added product. The exclusive right of intellectual property can not only achieve high premiums and product positioning. It can also help the public to realize the brand to achieve rapid spread and so on.
            </p>

            <Image src={TrademarkImg} className="" alt="infringement issues trademark"/>

            <p>
              Some small and medium-sized sellers, especially individual sellers, will pay more attention to investment issues when entering the eCommerce industry, and often bump into the wall in terms of brand investment, leading to frequent infringements when the store improves slightly. This pit is almost fatal for some sellers. Today, we are going to introduce four types of pits: trademark infringement, patent infringement, copyright infringement, and image theft.
            </p>

            <h5>
              <strong>
                (1) Trademark infringement
              </strong>
            </h5>

            <p>
              Fraudulent use of a trademark that is the same or similar to another's registered trademark. For example, the brand name of another person is used in the publication, or the pronoun and common name of the product are not distinguished, so that the trademark of another person is misused;
            </p>

            <h5>
              <strong>
                (2) Patent infringement
              </strong>
            </h5>

            <p>
              The infringement of appearance patent, such as the sale of products with appearance similar to others' appearance patent;
            </p>

             <h5>
              <strong>
                (3) Copyright infringement
              </strong>
            </h5>

            <p>
              Free use of the original work of others, copyright infringement mainly includes copyrighted pictures, patterns, designs, templates, product descriptions, etc.;
            </p>

            <h5>
              <strong>
                (4) Stealing pictures
              </strong>
            </h5>

            <p>
              As long as the pictures are not taken by the seller themselves, no matter where the pictures originate, there may be complaints about stealing pictures.
            </p>

            <Image src={Trademark2Img} className="" alt="stealing pictures trademark"/>

            <BottomShare>
              <div className="column-half">
                <p>Share the article</p>
              </div>
              <div className="column-half last">
                <FacebookShareButton url={href}>
                  <ShareButton><img src={FacebookDefaultIcon} alt="Facebook Icon" /> <span>Share</span></ShareButton>
                </FacebookShareButton>
                <TwitterShareButton url={href}>
                  <ShareButton><img src={TwitterDefaultIcon} alt="Twitter Icon" /> <span>Tweet</span></ShareButton>
                </TwitterShareButton>
                <LinkedinShareButton url={href}>
                  <ShareButton><img src={LinkedinDefaultIcon} alt="Linked Icon" /> <span>Share</span></ShareButton>
                </LinkedinShareButton>
              </div>
              <Clear></Clear>
            </BottomShare> 
          </div>  
          <Clear></Clear>
       </BlogRow> 
      </BlogPost>
      <SectionFull>
        <BlogRow>
          <h2>More From Shipkoo</h2>
          <PostsWrapper>
            {blogPosts.map(post => (
              <BlogBaseCard key={`${post.title}-${post.id}`} post={post} />
            ))}
          </PostsWrapper>
        </BlogRow>
      </SectionFull>
      <BlogOptin />
    </Layout>
  );
};

export default BlogArticle;